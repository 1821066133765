import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f52f58d2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_descriptions_item = _resolveComponent("el-descriptions-item")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_descriptions = _resolveComponent("el-descriptions")!
  const _component_el_main = _resolveComponent("el-main")!

  return (_openBlock(), _createBlock(_component_el_main, null, {
    default: _withCtx(() => [
      _createVNode(_component_el_descriptions, {
        title: "Details",
        direction: "vertical",
        column: 1,
        border: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_descriptions_item, { label: "ID" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.data.id), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "Subject" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.data.subject), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "Name" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.data.name), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "Email" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.data.email), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "Phone" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.data.phone), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "Message" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.data.message), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "Attachment" }, {
            default: _withCtx(() => [
              _createElementVNode("a", {
                href: _ctx.data.attachment_link
              }, "Attachment", 8, _hoisted_1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "Status" }, {
            default: _withCtx(() => [
              (_ctx.data.status === 'solved')
                ? (_openBlock(), _createBlock(_component_el_tag, {
                    key: 0,
                    type: "success"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.data.status), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.data.status === 'opening')
                ? (_openBlock(), _createBlock(_component_el_tag, {
                    key: 1,
                    type: "warning"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.data.status), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}